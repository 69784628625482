import React, { createContext, useContext, useState } from 'react';
import api from '../api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [tasks, setTasks] = useState({});
  const [error, setError] = useState(null);

  const login = async (phone, password) => {
    try {
      const response = await api.post('/login', {
        phone,
        password
      });

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        setToken(response.data.access_token);
        setError(null);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.message || 'Login failed');
      throw error;
    }
  };

  const signup = async (phone, password) => {
    try {
      const response = await api.post('/signup', {
        phone,
        password
      });
      setError(null);
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.response?.data?.message || 'Signup failed');
      throw error;
    }
  };

  const forgotPassword = async (phone) => {
    try {
      const response = await api.post('/forgot-password', { phone });
      setError(null);
      return response.data;
    } catch (error) {
      console.error('Forgot password error:', error);
      setError(error.response?.data?.message || 'Failed to process forgot password request');
      throw error;
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await api.post('/reset-password', {
        token,
        new_password: newPassword
      });
      setError(null);
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error);
      setError(error.response?.data?.message || 'Failed to reset password');
      throw error;
    }
  };

  const adminLogin = async (email, password) => {
    try {
      const response = await api.post('/admin/login', {
        email,
        password
      });

      if (response.data.access_token) {
        localStorage.setItem('adminToken', response.data.access_token);
        setError(null);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Admin login error:', error);
      setError(error.response?.data?.message || 'Admin login failed');
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('adminToken');
    setToken(null);
    setUser(null);
    setTasks({});
    setError(null);
  };

  const clearError = () => {
    setError(null);
  };

  const value = {
    token,
    user,
    tasks,
    error,
    setUser,
    setTasks,
    login,
    signup,
    forgotPassword,
    resetPassword,
    adminLogin,
    logout,
    clearError
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;