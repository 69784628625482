import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const InterviewCompleteDialog = ({ open, score, onClose, onDashboard }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center', pt: 3 }}>
        <CheckCircleIcon sx={{ color: 'success.main', fontSize: 40, mb: 1 }} />
        <Typography variant="h6">Interview Complete!</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Your Total Score:
          </Typography>
          <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
            {score}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'center' }}>
        <Button onClick={onDashboard} variant="contained" color="primary">
          Go to Dashboard
        </Button>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InterviewCompleteDialog;