import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline, Box } from "@mui/material";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ResetPassword from "./components/ResetPassword";
import Dashboard from "./components/Dashboard";
import CVUpload from "./components/CVUpload";
import ProfileCompletion from "./components/ProfileCompletion";
import VirtualInterview from "./components/VirtualInterview";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import Navigation from "./components/Navigation";
import { useAuth } from "./contexts/AuthContext";

const theme = createTheme({
  palette: {
    primary: {
      main: '#f47920', // Neptune Orange
      light: '#ff9d4d',
      dark: '#c85800',
    },
    secondary: {
      main: '#006838', // Neptune Green
      light: '#439063',
      dark: '#004012',
    }
  }
});

const App = () => {
  const { tasks, token } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Navigation />
          <Box sx={{ flex: 1 }}>
            <Routes>
              <Route
                path="/"
                element={
                  token ? (
                    tasks?.["Uploading CV"] ? (
                      tasks?.["Completing the Profile"] ? (
                        <Navigate to="/dashboard" />
                      ) : (
                        <Navigate to="/profile-completion" />
                      )
                    ) : (
                      <Navigate to="/cv-upload" />
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password/:token?" element={<ResetPassword />} />
              <Route
                path="/dashboard"
                element={token ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route
                path="/cv-upload"
                state={{}}
                element={token ? <CVUpload /> : <Navigate to="/login" />}
              />
              <Route
                path="/profile-completion"
                element={token ? <ProfileCompletion /> : <Navigate to="/login" />}
              />
              <Route
                path="/virtual-interview/:id"
                element={token ? <VirtualInterview /> : <Navigate to="/login" />}
              />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;