import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import api from "../api";
import logo from "../assets/logo.jpg";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

const CVUpload = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { token, setTasks } = useAuth();
  const navigate = useNavigate();

  const validateFile = (file) => {
    if (!file) {
      throw new Error("Please select a file");
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      throw new Error("Please upload only PDF or Word documents");
    }

    if (file.size > MAX_FILE_SIZE) {
      throw new Error("File size should not exceed 5MB");
    }

    return true;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    try {
      if (selectedFile) {
        validateFile(selectedFile);
        setFile(selectedFile);
        setFileName(selectedFile.name);
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setFile(null);
      setFileName("");
      e.target.value = '';
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      setSnackbarMessage("Please select a CV file to upload");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
        // Add timeout and retry logic
        timeout: 30000, // 30 seconds
        retry: 3,
        retryDelay: 1000,
      });

      console.log("Raw API response:", response.data);

      let profileData = {};
      let errorMessage = null;

      if (typeof response.data === 'string') {
        // Handle string response
        const lines = response.data.split("\n");
        for (const line of lines) {
          if (line.trim()) {
            try {
              const parsedData = JSON.parse(line);
              if (parsedData.type === "error") {
                errorMessage = parsedData.content;
              } else {
                profileData = { ...profileData, ...parsedData };
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          }
        }
      } else {
        // Handle JSON response
        profileData = response.data;
      }

      if (Object.keys(profileData).length === 0) {
        throw new Error("No valid profile data extracted from the response");
      }

      setSnackbarMessage("CV uploaded successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Update tasks
      setTasks((prev) => ({
        ...prev,
        "Uploading CV": true,
      }));

      // Navigate to profile completion
      navigate("/profile-completion", {
        state: { profileData },
        replace: true,
      });
    } catch (error) {
      console.error("Error in CV upload:", error);
      let errorMessage = "Error uploading CV. ";
      
      if (error.code === "ERR_NETWORK") {
        errorMessage += "Please check your internet connection and try again.";
      } else if (error.response?.status === 413) {
        errorMessage += "File size too large. Please upload a smaller file.";
      } else if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else {
        errorMessage += "Please try again later.";
      }
      
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, backgroundColor: "#f5f5f5" }}>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </Box>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          sx={{ mb: 2, color: "orange" }}
        >
          Upload Your CV
        </Typography>
        <Box
          component="form"
          onSubmit={handleUpload}
          noValidate
          sx={{ mt: 3, width: "100%" }}
        >
          <input
            accept=".pdf,.doc,.docx"
            style={{ display: "none" }}
            id="cv-upload"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="cv-upload">
            <Button
              variant="outlined"
              component="span"
              sx={{
                mt: 2,
                width: "100%",
                textTransform: "none",
                borderColor: "green",
                color: "green",
              }}
            >
              Choose CV
            </Button>
          </label>

          {fileName && (
            <Typography variant="body2" sx={{ mt: 1, color: "#555" }}>
              Selected File: {fileName}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              bgcolor: "orange",
              "&:hover": { bgcolor: "#ff8c00" },
              color: "white",
            }}
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress size={24} color="inherit" />
                {uploadProgress > 0 && (
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {`${uploadProgress}%`}
                  </Typography>
                )}
              </>
            ) : (
              "Upload CV"
            )}
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CVUpload;