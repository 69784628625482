import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import logo from "../assets/logo.jpg";

const Signup = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error"
  });
  
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signup, error, clearError } = useAuth();

  useEffect(() => {
    return () => clearError();
  }, [clearError]);

  const handleInputChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleTogglePasswordVisibility = (field) => () => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const showError = (message) => {
    setSnackbar({
      open: true,
      message,
      severity: "error"
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    
    setSnackbar({ open: false, message: "", severity: "error" });

    // Validate email
    if (!validateEmail(formData.email)) {
      showError("Please enter a valid email address");
      return;
    }

    // Validate password length
    if (formData.password.length < 6) {
      showError("Password must be at least 6 characters long");
      return;
    }

    // Validate password match
    if (formData.password !== formData.confirmPassword) {
      showError("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      await signup(formData.email, formData.password);
      
      setSnackbar({
        open: true,
        message: "Account created successfully! Redirecting to login...",
        severity: "success"
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, backgroundColor: "#ffffff" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", height: "auto", marginBottom: "1rem" }}
          />

          <Typography component="h1" variant="h5" sx={{ color: "orange", mb: 3 }}>
            Sign Up
          </Typography>

          <Box 
            component="form" 
            onSubmit={handleSignup} 
            noValidate 
            sx={{ width: '100%' }}
          >
            <TextField
              required
              fullWidth
              margin="normal"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange("email")}
              sx={{
                backgroundColor: "#f9f9f9",
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'orange',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'orange',
                  },
                },
              }}
            />

            <TextField
              required
              fullWidth
              margin="normal"
              name="password"
              label="Password"
              type={showPassword.password ? "text" : "password"}
              value={formData.password}
              onChange={handleInputChange("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility("password")}
                      edge="end"
                    >
                      {showPassword.password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#f9f9f9",
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'orange',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'orange',
                  },
                },
              }}
            />

            <TextField
              required
              fullWidth
              margin="normal"
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword.confirmPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={handleInputChange("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility("confirmPassword")}
                      edge="end"
                    >
                      {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#f9f9f9",
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'orange',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'orange',
                  },
                },
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: 'orange',
                '&:hover': {
                  backgroundColor: '#ff8c00',
                },
                height: 48
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Sign Up"}
            </Button>

            <Box sx={{ textAlign: 'center' }}>
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "green",
                  fontSize: "0.9rem",
                }}
              >
                Already have an account? Sign in
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Signup;