import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { LogoutOutlined, AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Navigation = () => {
  const { user, logout, token } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Don't show nav bar on login and signup pages
  if (!token) return null;

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'orange' }}>
          Neptune Learning
        </Typography>
        {token && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'green' }}>
              <AccountCircle />
              <Typography variant="body2">
                {user?.personalInfo?.name || 'User'}
              </Typography>
            </Box>
            <Button 
              variant="contained" 
              onClick={handleLogout}
              startIcon={<LogoutOutlined />}
              sx={{
                backgroundColor: 'orange',
                '&:hover': {
                  backgroundColor: '#ff8c00',
                },
                textTransform: 'none'
              }}
            >
              Logout
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;