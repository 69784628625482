import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Button, 
  TextField, 
  Typography, 
  Box,
  Avatar,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Snackbar,
  CircularProgress
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import api from '../api';
import logo from "../assets/logo.jpg";

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();
  const { token } = useParams();

  const steps = ['Enter Email', 'Set New Password'];

  useEffect(() => {
    if (token) {
      setActiveStep(1);
    }
  }, [token]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const showSnackbarMessage = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSendResetLink = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      showSnackbarMessage('Please enter a valid email address', 'error');
      return;
    }

    setLoading(true);

    try {
      const response = await api.post('/forgot-password', { email });
      showSnackbarMessage('Reset link sent to your email. Please check your inbox.', 'success');
    } catch (error) {
      showSnackbarMessage(error.response?.data?.message || 'Failed to send reset link', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      showSnackbarMessage("Passwords don't match", 'error');
      return;
    }

    if (newPassword.length < 6) {
      showSnackbarMessage("Password must be at least 6 characters long", 'error');
      return;
    }

    setLoading(true);
    try {
      await api.post('/reset-password', {
        token,
        new_password: newPassword
      });
      
      showSnackbarMessage('Password reset successfully! Redirecting to login...', 'success');
      
      // Wait for 2 seconds to show the success message, then redirect
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      showSnackbarMessage(error.response?.data?.message || 'Failed to reset password', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, backgroundColor: "#ffffff" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", height: "auto", marginBottom: "1rem" }}
          />
          
          <Avatar sx={{ m: 1, bgcolor: 'orange' }}>
            <LockOutlinedIcon />
          </Avatar>
          
          <Typography component="h1" variant="h5" sx={{ color: 'orange', mb: 3 }}>
            Reset Password
          </Typography>
          
          <Stepper activeStep={activeStep} sx={{ width: '100%', mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          
          <Box component="form" 
            onSubmit={activeStep === 0 ? handleSendResetLink : handleResetPassword} 
            sx={{ width: '100%' }}
          >
            {activeStep === 0 ? (
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={email && !validateEmail(email)}
                helperText={email && !validateEmail(email) ? "Invalid email address" : ""}
                sx={{
                  backgroundColor: "#f9f9f9",
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'orange',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'orange',
                    },
                  },
                }}
              />
            ) : (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  sx={{
                    backgroundColor: "#f9f9f9",
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'orange',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'orange',
                      },
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  sx={{
                    backgroundColor: "#f9f9f9",
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'orange',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'orange',
                      },
                    },
                  }}
                />
              </>
            )}
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: 'orange',
                '&:hover': {
                  backgroundColor: '#ff8c00',
                },
                height: 48
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                activeStep === 0 ? 'Send Reset Link' : 'Reset Password'
              )}
            </Button>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPassword;