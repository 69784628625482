import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import logo from "../assets/logo.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login, error: authError } = useAuth();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate email
    if (!validateEmail(email)) {
      setSnackbarMessage("Please enter a valid email address");
      setSnackbarOpen(true);
      return;
    }

    // Validate password
    if (!password) {
      setSnackbarMessage("Please enter your password");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const result = await login(email, password);
      console.log("Login result:", result);

      if (result) {
        navigate("/");
      } else {
        setSnackbarMessage(authError || "Login failed");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(error.message || "An error occurred during login");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, borderRadius: 2 }}>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", height: "auto" }}
          />
        </Box>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          sx={{ color: "orange" }}
        >
          Login
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            InputLabelProps={{
              sx: {
                color: "lightgray",
                "&.Mui-focused": {
                  color: "green",
                },
              },
            }}
            sx={{
              bgcolor: "#f9f9f9",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "lightgray",
                },
                "&:hover fieldset": {
                  borderColor: "green",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                },
              },
            }}
            error={email && !validateEmail(email)}
            helperText={email && !validateEmail(email) ? "Invalid email address" : ""}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                color: "lightgray",
                "&.Mui-focused": {
                  color: "green",
                },
              },
            }}
            sx={{
              bgcolor: "#f9f9f9",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "lightgray",
                },
                "&:hover fieldset": {
                  borderColor: "green",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                },
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "orange",
              "&:hover": { bgcolor: "#ff8c00" },
              color: "white",
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Sign In"}
          </Button>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Link
                to="/signup"
                style={{
                  textDecoration: "none",
                  color: "green",
                  fontSize: "0.9rem",
                }}
              >
                Don't have an account? Sign Up
              </Link>
              <Link
                to="/reset-password"
                style={{
                  textDecoration: "none",
                  color: "orange",
                  fontSize: "0.9rem",
                }}
              >
                Forgot Password?
              </Link>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <Link
                to="/admin-login"
                style={{
                  textDecoration: "none",
                  color: "orange",
                  fontSize: "0.9rem",
                }}
              >
                Admin Login
              </Link>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;